<template>
  <div class="hovering__pad py-3">
    <div class="px-3 mb-3 d-flex">
      <b-button v-if="!csvLoad" @click="csvLoad = !csvLoad" class="mr-3" variant="outline-default"
        >Загрузить CSV</b-button
      >
      <div v-else class="d-flex">
        <file-input
          v-model="csvUploadForm.file"
          type="file"
          :accept="'.csv'"
          class="mr-3"
          :loadText="'Выберите файл'"
          :disableFileMark="true"
        /><b-input
          v-model="csvUploadForm.question_type"
          class="ph-36 mr-3"
          placeholder="Тип вопросов"
        ></b-input
        ><b-checkbox class="mt-2 mr-3 align-center" v-model="csvUploadForm.replace"
          >Заменить</b-checkbox
        ><b-button @click="uploadCsv" :disabled="csvLoading" variant="yellow"
          >Отправить <b-spinner style="width: 20px; height: 20px" v-if="csvLoading"></b-spinner
        ></b-button>
      </div>
    </div>
    <b-table v-if="questions" :fields="fields" :items="questions" stacked="sm" responsive>
      <template #cell(_id)="data">{{ data.value.$oid }}</template>
      <template #cell(meta)="data"
        ><b-button
          variant="outline-default"
          @click="$bvModal.show(`MnaoawunjwA${data.item._id.$oid}`)"
          >Показать</b-button
        >

        <b-modal
          size="lg"
          :title="`Поля ${data.item._id.$oid}`"
          :id="`MnaoawunjwA${data.item._id.$oid}`"
        >
          <b-table
            class="mt-2"
            :items="
              Object.entries(data.value.fields).map((field) => {
                return { name: field[0], ...field[1] };
              })
            "
            :fields="metaFields"
            stacked="sm"
            responsive
          ></b-table></b-modal
      ></template>
      <template #cell(subject)="data"
        ><b-button
          variant="outline-default"
          @click="$bvModal.show(`<MciaIUwjwA${data.item._id.$oid}`)"
          >Показать</b-button
        >

        <b-modal
          size="lg"
          :title="`Субьекты ${data.item._id.$oid}`"
          :id="`<MciaIUwjwA${data.item._id.$oid}`"
        >
          <b-table
            class="mt-2"
            :items="
              Object.entries(data.value.meta.fields).map((field) => {
                return { name: field[0], ...field[1], content: data.item.subject.body[field[0]] };
              })
            "
            :fields="[...metaFields, { key: 'content', label: 'Содержание' }]"
            stacked="sm"
            responsive
          ></b-table
        ></b-modal>
      </template>
      <template #cell(body)="data"
        ><b-button
          variant="outline-default"
          @click="$bvModal.show(`CmkaiwUwjwA${data.item._id.$oid}`)"
          >Показать</b-button
        >
        <b-modal :title="`Ответы ${data.item._id.$oid}`" :id="`CmkaiwUwjwA${data.item._id.$oid}`">
          <b-table
            class="mt-2"
            :items="
              Object.entries(data.value).map((field) => {
                return {
                  name: data.item.meta.fields[field[0]]
                    ? data.item.meta.fields[field[0]].label
                    : field[0],
                  value: field[1] === '' ? 'Не отображается' : field[1],
                };
              })
            "
            :fields="answersFields"
            stacked="sm"
            responsive
          ></b-table
        ></b-modal>
      </template>
    </b-table>
    <loader v-else></loader>
  </div>
</template>

<script>
import education from '@main/api/education';
import Loader from '../Loader.vue';
import FileInput from '../forms/FileInput.vue';

export default {
  components: { Loader, FileInput },
  data: () => ({
    params: { page: 0 },
    loading: false,
    questions: null,
    csvLoad: false,
    csvLoading: false,
    csvUploadForm: {
      file: null,
      question_type: null,
      replace: false,
    },
  }),
  computed: {
    fields() {
      return [
        { key: '_id', label: 'ID' },
        { key: 'question_type', label: 'Тип вопроса' },
        { key: 'meta', label: 'Поля' },
        { key: 'subject', label: 'Субьекты' },
        { key: 'body', label: 'Ответы' },
      ];
    },
    metaFields() {
      return [
        { key: 'name', label: 'Имя' },
        { key: 'type', label: 'Тип' },
        { key: 'label', label: 'Отображение' },
        { key: 'read_only', label: 'Только чтение' },
        { key: 'required', label: 'Обязателен' },
        { key: 'metatype', label: 'Метатип' },
        { key: 'answer_explanation', label: 'Ссылка на обьяснение ответа' },
        { key: 'api_widget', label: 'Виджет API' },
      ];
    },
    answersFields() {
      return [
        { key: 'name', label: 'Поле' },
        { key: 'value', label: 'Ответ' },
      ];
    },
  },
  methods: {
    async updateData() {
      this.loading = true;
      try {
        const result = await education.v2.questions.list(this.params);
        this.questions = result.data_array.map((val) => {
          return { ...val, question_type: val.meta.question_type };
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async uploadCsv() {
      this.csvLoading = true;
      try {
        const result = await education.v2.questions.upload.csv({
          ...this.csvUploadForm,
          file: this.csvUploadForm.file[0],
        });
        await this.updateData();
        this.csvLoad = false;
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(e.response || 'Ошибка, попробуйте позднее');
      } finally {
        this.csvLoading = false;
      }
    },
  },
  async mounted() {
    await this.updateData();
  },
};
</script>
